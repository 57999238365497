import mock from 'src/utils/mock';

mock
  .onGet('/api/clients/sources')
  .reply(() => {
    const sources = {
      "algolead": {
        id: "algolead",
        name: "AlgoLead",
        accounts: {
          "Josh": {
            id: "Josh",
            name: "Josh",
          },
        },
      },
    };
    return [200, {
      sources
    }];
  });

mock
  .onGet('/api/clients/columns/algolead')
  .reply(() => {
    const columns = [{
      field: "UserID",
      title: "User ID"
    }, {
      field: "AccountID",
      title: "Account ID"
    }, {
      field: "Country",
      title: "Country"
    }, {
      field: "SaleStatus",
      title: "Sale Status"
    }, {
      field: "Balance",
      title: "Balance",
      align: "right",
      type: "numeric"
    }, {
      field: "CreateTime",
      title: "Create Time",
      type: "date"
    }
  ];

    return [200, {
      columns
    }];
  });
